import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react'; // Import the useToast hook from Chakra UI
import axios from 'axios'; // Import Axios
import fullLogo from '../icons/YTT5.jpg'; // Assuming the full logo is used in the image section
import companyLogo from '../icons/bnmrLogo.png'; // Assuming the full logo is used in the image section

const ColdTrafficLP1 = () => {
  const history = useNavigate();
  const toast = useToast();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [investmentCapacity, setInvestmentCapacity] = useState('');
  
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isInvestmentVisible, setIsInvestmentVisible] = useState(false);
  
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsNameValid(name.length >= 2);
    if (isNameValid) setIsEmailVisible(true);
  }, [name, isNameValid]);

  useEffect(() => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailPattern.test(email));
    if (isEmailValid) setIsPhoneVisible(true);
  }, [email, isEmailValid]);

  useEffect(() => {
    const phonePattern = /^(\+?1\s?)?(\([0-9]{3}\)|[0-9]{3})([.\-\s]?)?[0-9]{3}([.\-\s]?)?[0-9]{4}$/;
    setIsPhoneValid(phonePattern.test(phone));
    if (isPhoneValid) setIsInvestmentVisible(true);
  }, [phone, isPhoneValid]);

  useEffect(() => {
    setIsButtonActive(isNameValid && isEmailValid && isPhoneValid && investmentCapacity !== '');
  }, [isNameValid, isEmailValid, isPhoneValid, investmentCapacity]);

  const getCreativeID = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('creativeID');
  };

  const getCampaignName = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('campaignName');
  };
  
  const handleFormSubmit = (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      
      if (isButtonActive) {
        const creativeID = getCreativeID();  // Capture creativeID
        const campaignName = getCampaignName();  // Capture campaignName
        
        axios.post('https://retainlysms-backend.herokuapp.com/funnel-form', {
          name,
          email,
          phone,
          investmentCapacity,
          creativeID: creativeID, // Include creativeID in form submission
          campaignName: campaignName // Include campaignName in form submission
        })
        .then(response => {
          if (window.fbq) {
            window.fbq('track', 'Lead');
          }
          if (investmentCapacity === "No, less than $5,000") {
              history('/dq');
            } else {
              history('/mql');
            }
        })
        .catch(error => {
          toast({
            title: "Error",
            description: "There was an issue submitting the form. Please try again.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      } else {
        setIsSubmitting(false);
        toast({
          title: "Error",
          description: "Please fill out all fields correctly.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
  };
  

  return (
    <div className="flex px-2 justify-center h-screen py-4 ">
     
      <style>
        {`
          .shadow-custom {
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
          }
         
          .animate-fade-in {
            animation: fade-in 0.5s ease-in-out;
          }
          @keyframes fade-in {
            0% { opacity: 0; transform: translateY(10px); }
            100% { opacity: 1; transform: translateY(0); }
          }
            
        `}
      </style>
      
      <div className="w-full  bg-white shadow-top-prominent rounded-3xl">
      <nav className="w-full bg-white px-2 flex justify-between items-center"
      style={{
        backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      >
      <div className="flex items-center">
        <img
          src="https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cf3_new%20Benmore.svg"
          alt="Benmore Technologies"
          className="h-8 md:h-12"
        />
      </div>

    </nav>
     
        <form onSubmit={handleFormSubmit} className="space-y-6 p-6 pt-0">
        <div
          className="text-center"
          style={{
            backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <h1 className="font-extrabold pt-6 text-2xl md:text-5xl md:px-[10%]">
            How to Turn Your <span className='text-[#1D54E2]'>Software Idea</span> Into a Reality in 180 Days Without Wasting Time or Money Using Our <span className='text-[#1D54E2]'>"Blueprint"</span> Method
          </h1>

          <h2 className="mt-4 text-lg md:text-2xl">
            <span className='font-bold'>STEP 1:</span> Watch This Video (Sound On)
          </h2>
        </div>

          <div 
            className="text-center w-full md:w-3/5 mx-auto relative pb-[56%] md:pb-[30%] h-0 overflow-clip"
            >
            {/* <iframe 
                src="https://player.vimeo.com/video/801111884?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                title="The 3-Step Process That Places Businesses on The 1st Page of Google In Less Than 12 Weeks"
            >
            </iframe> */}
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/nhVoofwfBq8?si=Xvw_eRTmQ6BTEME"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            </div>



            <div className='md:px-[10%]'>
            <h2 className="text-center my-4 pt-4 text-lg md:text-2xl"><span className='font-bold'>STEP 2: </span>See if you qualify for a free Discovery Call</h2>

            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full outline-[#2E5AF4] px-4 py-3 rounded-lg border-2"
            />

            {isEmailVisible && (
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full outline-[#2E5AF4] px-4 py-3 rounded-lg mt-4 border-2"
              />
            )}

            {isPhoneVisible && (
              <input
                type="tel"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full outline-[#2E5AF4] px-4 py-3 rounded-lg mt-4 border-2"
              />
            )}

            {isInvestmentVisible && (
              <div className="mt-4">
                <p className="mb-2">Building an app requires an investment of time and capital. Would you have enough time, and a minimum of $10,000 - 20,000 USD in funds available to invest, if you were to find the right team?</p>
                <div>
                  <label className="block mt-2">
                    <input
                      type="radio"
                      name="investmentCapacity"
                      value="Yes, over $20,000"
                      onChange={(e) => setInvestmentCapacity(e.target.value)}
                      className="mr-2"
                    />
                    Yes, over $20,000
                  </label>
                  <label className="block mt-2">
                    <input
                      type="radio"
                      name="investmentCapacity"
                      value="Yes, between $10,000 - $20,000"
                      onChange={(e) => setInvestmentCapacity(e.target.value)}
                      className="mr-2"
                    />
                    Yes, between $10,000 - $20,000
                  </label>
                  <label className="block mt-2">
                    <input
                      type="radio"
                      name="investmentCapacity"
                      value="No, between $5,000 - $10,000"
                      onChange={(e) => setInvestmentCapacity(e.target.value)}
                      className="mr-2"
                    />
                    No, between $5,000 - $10,000
                  </label>
                  <label className="block mt-2">
                    <input
                      type="radio"
                      name="investmentCapacity"
                      value="No, less than $5,000"
                      onChange={(e) => setInvestmentCapacity(e.target.value)}
                      className="mr-2"
                    />
                    No, less than $5,000
                  </label>
                </div>
              </div>
            )}

            <button
              type="submit"
              disabled={!isButtonActive}
              className={`w-full py-3 mt-6 text-white font-bold rounded-lg ${isButtonActive ? 'bg-[#2E5AF4]' : 'bg-gray-400'}`}
            >
              {isSubmitting ? (
                <div className="blackSpinner"></div>
              ) : (
                <p>Submit application</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ColdTrafficLP1;
